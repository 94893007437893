.select_list {
  &_wrapper {
    max-width: 400px;
  }

  &_title {
    font-family: "IBM Plex Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 8px;
  }

  &_component {
    width: 100%;

    :global {
      .ant-select-selector {
        min-height: 34px !important;
        background-color: #2f2f39 !important;
        border: 1px solid #40404d !important;
        border-radius: 4px;
        color: #ffffff;

        &__clear {
          padding-top: 6px;
          padding-bottom: 7px;
          background-color: #2f2f39;

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }

      .ant-select-selection-placeholder {
        line-height: 30px !important;
      }

      .ant-select-selection-item {
        color: #ffffff !important;
        background-color: #2f2f39 !important;
      }

      .ant-select-selection-item-remove {
        color: #ffffff !important;
      }

      .ant-select-selection--single {
        .ant-select-selection__clear {
          padding-top: 0px !important;
        }
      }

      .ant-select-selection__rendered {
        margin-bottom: 0px !important;
      }

      .ant-select-arrow {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}
