.box {
  display: flex;
  width: 100%;

  .wrap {
    width: 50%;
    color: white;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    .wrap {
      width: 100%;
    }
  }
}
