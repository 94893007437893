.filter_item {
  flex: 1 1 auto;
  margin-right: 15px;
  max-width: 25%;

  &_action {
    flex: unset;
    padding-bottom: 3px;
  }
}
