.radio_group {
  &_wrapper {
    padding-top: 15px;

    width: 100%;
    overflow: auto;
    white-space: nowrap;

    :global {
      .ant-radio-group {
        width: inherit;
        border-bottom: 1px solid #e8e8e842;
      }
    }
  }

  &_button {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    color: #fff !important;
    border-radius: 0 !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    height: 100% !important;
    padding: 10px 16px !important;
  }

  :global {
    .ant-radio-button-wrapper-checked {
      border-bottom: 3px solid #2ba6ff !important;
      color: #2ba6ff !important;
    }

    .ant-radio-button-wrapper:not(:first-child)::before,
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      width: 0 !important;
    }
  }
}
